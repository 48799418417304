import {
  Formatter,
  staticProjectorAccessoriesData,
  staticProjectorRentalData,
  useBusiness,
  Validators,
} from "@with-nx/hooks-n-helpers";
import { useRegion, useStatesCodes } from "@with-nx/region";
import {
  DesignedInput,
  DesignedRadio,
  DesignedSelect,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import ReusableCheckboxSidebar from "../reusable-checkbox-sidebar/reusable-checkbox-sidebar";
import { ContactDetails } from "./types";

export type GetStartedDetails = {
  name?: string;
  organization?: string;
  organization_type?: string;
  email?: string;
  phone_number?: string;
  show?: string;
  opening_night?: string;
  closing_night?: string;
  message?: string;
  customer_type?: "New Customer" | "Returning Customer";
  region?: string;
  state?: string;
  products?: string[];
};

interface GetStartedFormProps {
  initial?: GetStartedDetails;
  value?: GetStartedDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
  action?: "get-started" | string;
  referrer?: string;
}

export const GetStartedForm = (props: GetStartedFormProps) => {
  const [values, _values] = useState<GetStartedDetails>({
    customer_type: "New Customer",
    region: "🇺🇸 United States",
    state: "AL",
    ...props?.initial,
  });
  const [valid, _valid] = useState(true);
  const [ready, _ready] = useState(false);
  const business = useBusiness();

  const region = useRegion();

  const openingNightError = (() => {
    if (values?.opening_night?.length) {
      if (!moment(values?.opening_night, "YYYY-MM-DD", true).isValid()) {
        return "Closing night date is not valid.";
      }

      if (moment(values?.opening_night).isBefore(moment())) {
        return "Opening Night date must be in the future";
      }
    }
    return undefined;
  })();

  const closingNightDateError = (() => {
    if (values?.closing_night?.length) {
      if (!moment(values?.closing_night, "YYYY-MM-DD", true).isValid()) {
        return "Closing night date is not valid.";
      }

      if (
        moment(values?.closing_night).isBefore(moment(values?.opening_night))
      ) {
        return "Closing night date must be greater or equal than Opening night date.";
      }
    }

    return undefined;
  })();

  const emailError = (() => {
    if (!Validators.isValidEmailAddress(values.email || "")) {
      return "Please enter a valid email address.";
    }

    return undefined;
  })();

  useEffect(() => {
    let _valid = valid;

    if (
      values.name?.length === 0 ||
      values.name === undefined ||
      !values?.name?.includes(" ")
    ) {
      _valid = false;
    }

    if (
      Number(values.organization?.length) <= 4 ||
      values.organization === undefined
    ) {
      _valid = false;
    }

    if (
      !values.organization_type?.length ||
      values.organization_type === undefined
    ) {
      _valid = false;
    }

    if (!Validators.isValidEmailAddress(values.email || "")) {
      _valid = false;
    }

    if (!values.phone_number?.length || values.phone_number === undefined) {
      _valid = false;
    }

    if (!values.opening_night?.length || values.opening_night === undefined) {
      _valid = false;
    }

    if (!values.closing_night?.length || values.closing_night === undefined) {
      _valid = false;
    }

    if (moment(values.opening_night).isBefore(moment())) {
      _valid = false;
    }

    if (moment(values.closing_night).isBefore(moment(values.opening_night))) {
      _valid = false;
    }

    if (!values.customer_type?.length || values.customer_type === undefined) {
      _valid = false;
    }

    if (!values.show?.length || values.show === undefined) {
      _valid = false;
    }

    if (!values.region?.length || values.region === undefined) {
      _valid = false;
    }

    if (!values.message?.length || values.message === undefined) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  useEffect(() => {
    if (props.action === "get-started" && typeof props.referrer === "string") {
      if (props.referrer === "/scenic-projections") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Scenic Projections Landing page and I have some questions...",
        }));
        _values((values) => ({
          ...values,
          products: [...(values.products || []), "Digital Scenery"],
        }));
      }
      if (props.referrer === "/choreography-guides") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Choreography Guides Landing page and I have some questions...",
        }));
        _values((values) => ({
          ...values,
          products: [...(values.products || []), "Choreography Guides"],
        }));
      }
      if (props.referrer.includes("/stage-gear")) {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Stage Gear Landing page and I have some questions...",
          products: [
            ...(values.products || []),
            "Projector Hardware",
            "Wireless Microphone Systems",
          ],
        }));
      }
      if (props.referrer.includes("/bodymics")) {
        _values((values) => ({
          ...values,
          message:
            "I just came from the BodyMics Landing page and I have some questions...",
          products: [
            ...(values.products || []),
            "Projector Hardware",
            "Wireless Microphone Systems",
          ],
        }));
      }
      if (props.referrer.includes("/shows/")) {
        const show: string | undefined = props.referrer.split("/")[3];
        if (show) {
          const friendly = show
            ?.split("-")
            ?.map((i) => {
              if (i === "kids") {
                return "KIDS";
              } else if (i === "jr") {
                return "JR.";
              }
              return Formatter.friendly(i);
            })
            .join(" ");

          const _ps: string[] = [];

          if (props.referrer.includes("scenic-projections")) {
            _ps.push("Digital Scenery");
          }

          if (props.referrer.includes("choreography-guides")) {
            _ps.push("Choreography Guides");
          }

          _values((values) => ({
            ...values,
            message: `I just came from the ${friendly} landing page and I have some questions...`,
            show: friendly,
            products: [...(values.products || []), ..._ps],
          }));
        }
      }
      if (props.referrer.includes("/projectors/")) {
        const item: string | undefined = props.referrer.split("/").at(-1);
        if (item) {
          const hardware =
            staticProjectorRentalData.find((i) => i.slug === item) ||
            staticProjectorAccessoriesData.find((i) => i.slug === item);

          const _ps: string[] = [];
          _ps.push("Projector Hardware");

          _values((values) => ({
            ...values,
            message: `I’m interested in a "StageProjector" ${hardware?.name}...`,
            products: [...(values.products || []), ..._ps],
          }));
        }
      }
    }
  }, [props.action, props.referrer]);

  useEffect(() => {
    if (region.loading) {
      return;
    }

    _values((v) => ({
      ...v,
      region: region.r,
    }));

    setTimeout(() => {
      _ready(true);
    }, 200);
  }, [region.loading]);

  const states = useStatesCodes(values.region === "🇨🇦 Canada" ? "CA" : "US");

  if (!ready) {
    return null;
  }

  return (
    <Row gutter={[36, 18]}>
      <Col xs={24}>
        <Rule display="block" rule="lt" color="#AFAFAF" bottom={12} right={5}>
          Have we worked together before?
        </Rule>
        <Box parse="d:flex a:center fw:wrap">
          <DesignedRadio
            size="large"
            label="New Customer"
            active={values.customer_type === "New Customer"}
            press={() => _values({ ...values, customer_type: "New Customer" })}
            properties={{ right: 24 }}
          />
          <DesignedRadio
            size="large"
            label="Returning Customer"
            active={values.customer_type === "Returning Customer"}
            press={() =>
              _values({ ...values, customer_type: "Returning Customer" })
            }
            properties={{ right: 24 }}
          />
        </Box>
      </Col>
      <Col
        xs={24}
        sm={
          values.region === "🇺🇸 United States" || values.region === "🇨🇦 Canada"
            ? 12
            : 24
        }
      >
        <DesignedSelect
          label="Where are you located?"
          value={values.region}
          change={(region) => {
            _values({ ...values, region, state: undefined });
          }}
          options={region.regions?.map((i) => [i, i]) || []}
          bottom={12}
        />
      </Col>
      {values.region === "🇺🇸 United States" ? (
        <Col xs={24} sm={12}>
          <DesignedSelect
            label="Which state are you located?"
            value={values.state}
            change={(state) => _values({ ...values, state })}
            options={states?.map(([i, ii]) => [i, ii]) || []}
            bottom={12}
            required
            empty
          />
        </Col>
      ) : undefined}

      {values.region === "🇨🇦 Canada" ? (
        <Col xs={24} sm={12}>
          <DesignedSelect
            label="Which province are you located?"
            value={values.state}
            change={(state) => _values({ ...values, state })}
            options={states?.map(([i, ii]) => [i, ii]) || []}
            bottom={12}
            required
            empty
          />
        </Col>
      ) : undefined}

      <Col xs={24}>
        <DesignedInput
          label="Name"
          value={values.name}
          change={(name) => _values({ ...values, name })}
          bottom={12}
          error={(() => {
            if (!values?.name?.includes(" ")) {
              return "Please enter your full name.";
            }
            return undefined;
          })()}
          convert="capitalize"
        />
      </Col>
      <Col xs={12}>
        <DesignedInput
          label="Organization Name"
          value={values.organization}
          change={(organization) => _values({ ...values, organization })}
          bottom={12}
          convert="capitalize"
        />
      </Col>
      <Col xs={12}>
        <DesignedSelect
          label="Organization Type"
          change={(organization_type) =>
            _values({ ...values, organization_type })
          }
          empty
          bottom={12}
          options={business.types.map((i) => [
            i.businessType.toString(),
            i.name,
          ])}
        />
      </Col>
      <Col xs={12}>
        <DesignedInput
          label="Email"
          value={values.email}
          change={(email) => _values({ ...values, email })}
          bottom={12}
          blur={() => {
            _valid(Validators.isValidEmailAddress(values.email || ""));
          }}
          error={emailError}
          convert="lowercase"
        />
      </Col>
      <Col xs={12}>
        <DesignedInput
          label="Phone Number"
          value={values.phone_number}
          change={(phone_number) => _values({ ...values, phone_number })}
          bottom={12}
        />
      </Col>
      <Col xs={12} sm={8}>
        <DesignedInput
          label="Show"
          value={values.show}
          change={(show) => _values({ ...values, show })}
          bottom={12}
        />
      </Col>
      <Col xs={12} sm={8}>
        <DesignedInput
          label="Opening Night"
          value={values.opening_night}
          change={(opening_night) => _values({ ...values, opening_night })}
          bottom={12}
          type="date"
          error={openingNightError}
        />
      </Col>
      <Col xs={12} sm={8}>
        <DesignedInput
          label="Closing Night"
          value={values.closing_night}
          change={(closing_night) => _values({ ...values, closing_night })}
          bottom={12}
          type="date"
          error={closingNightDateError}
        />
      </Col>
      <Col xs={24}>
        <DesignedInput
          label="Message"
          value={values.message}
          change={(message) => _values({ ...values, message })}
          bottom={12}
          textarea={true}
        />
      </Col>
      <Col xs={24}>
        <Rule display="block" rule="lt" color="#AFAFAF" bottom={12} right={5}>
          What products are you interested in?
        </Rule>
        <ReusableCheckboxSidebar
          minimal
          groups={{
            items: {
              expanded: true,
              label: "",
              items: [
                {
                  label: "Digital Scenery",
                  value: "Digital Scenery",
                  active: values.products?.includes("Digital Scenery"),
                },
                {
                  label: "Projector Hardware",
                  value: "Projector Hardware",
                  active: values.products?.includes("Projector Hardware"),
                },
                {
                  label: "Wireless Microphone Systems",
                  value: "Wireless Microphone Systems",
                  active: values.products?.includes(
                    "Wireless Microphone Systems"
                  ),
                },
                {
                  label: "Choreography Guides",
                  value: "Choreography Guides",
                  active: values.products?.includes("Choreography Guides"),
                },
                {
                  label: "Other Resources",
                  value: "Other Resources",
                  active: values.products?.includes("Other Resources"),
                },
              ],
            },
          }}
          change={(groups) => {
            const is = (groups["items"].items.map((item) => item.value) ||
              []) as string[];
            _values({ ...values, products: is });
          }}
        />
      </Col>
    </Row>
  );
};
