import { useAuth } from "@with-nx/auth";
import {
  Pipedrive,
  useBusiness,
  useBusinessTypeName,
  useContact,
  useURL,
} from "@with-nx/hooks-n-helpers";
import { DesignedButton } from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import {
  GetStartedDetails,
  GetStartedForm,
} from "../contact-form/get-started-form";

export const AutomaticGetStarted = () => {
  const [ready, _ready] = useState(false);
  const [loading, _loading] = useState(false);
  const [valid, _valid] = useState(false);
  const [details, _details] = useState<GetStartedDetails | undefined>(
    undefined
  );
  const contact = useContact();

  const router = useRouter();

  const businessTypeNames = useBusinessTypeName();

  const submit = async () => {
    if (!details || loading) {
      return;
    }

    _loading(true);

    try {
      const meta: {
        [key: string]: string;
      } = {};

      if (details.show) {
        meta["Show"] = details.show;
      }
      if (details.opening_night) {
        meta["Opening Night"] = details.opening_night;
      }

      if (details.closing_night) {
        meta["Closing Night"] = details.closing_night;
      }

      if (details.organization_type) {
        meta["Organization Type"] = businessTypeNames.search(
          details.organization_type
        );
      }

      if (details.customer_type) {
        meta["Customer Type"] = details.customer_type;
      }

      if (details.products) {
        meta["Products"] = details.products.join(", ");
      }

      if (details.region) {
        meta["Region"] = details.region;
      }

      if (details.state) {
        meta["State"] = details.state;
      }

      if (details.message) {
        meta["Message"] = details.message;
      }

      const region = (() => {
        switch (details?.region) {
          case "🇺🇸 United States":
            return "US";
          case "🇨🇦 Canada":
            return "CA";
          case "🇦🇺 Australia":
            return "AU/NZ";
          case "🇳🇿 New Zealand":
            return "AU/NZ";
          case "🇬🇧 United Kingdom":
            return "GB/Europe";
          case "🇪🇺 Europe":
            return "GB/Europe";
          case "🌍 Global":
            return "OTHER";
          default:
            return "US";
        }
      })();

      try {
        const pipedrive = await Pipedrive.inquiry({
          isReturningCustomer: details?.customer_type === "Returning Customer",
          email: details?.email || "Unknown",
          name: details?.name
            ? details?.name?.split(" ")?.[0] || details?.name
            : "",
          surname: details?.name
            ? details?.name
                ?.split(" ")
                ?.splice(1, details?.name?.split(" ")?.length)
                ?.join(" ") || "*"
            : "",
          phone: details?.phone_number || "Unknown",
          region,
          state: details?.state,
          show: details?.show || "Unknown",
          message: details?.message || "Unknown",
          openingNight: details?.opening_night || "Unknown",
          closingNight: details?.closing_night || "Unknown",
          additionalData: meta,
          businessType: Number(details?.organization_type || "0"),
          organization: details?.organization || "Unknown",
          subject: "Get Started Form Submission",
        });
      } catch (error) {
        console.error("Pipedrive Error", error);
      }

      router.push("/contact/start/thank-you");
    } catch (error) {
      router.push("/contact/start/thank-you");
      console.error("Something went wrong. Please try again later.");
    }

    _loading(false);
  };

  const auth = useAuth();
  const business = useBusiness();

  useEffect(() => {
    setTimeout(() => {
      _ready(true);
    }, 200);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <GetStartedForm
        value={details}
        initial={{
          name: auth?.authState?.session?.user?.name,
          email: auth?.authState?.session?.user?.email,
          customer_type: !auth?.authState?.session?.user?.email
            ? "New Customer"
            : "Returning Customer",
          organization_type: business?.type?.name,
          organization: auth?.authState?.session?.user?.organization,
        }}
        action="get-started"
        referrer={window?.location?.pathname}
        change={(details, valid) => {
          _details(details);
          _valid(valid);
        }}
      />
      <Box parse="mt:24">
        <DesignedButton
          label="Submit Request"
          disable={valid ? false : ["Make sure all fields are filled out."]}
          press={submit}
          properties={{
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            },
          }}
          container={{
            style: {
              width: "100%",
              display: "flex",
            },
          }}
        />
      </Box>
    </>
  );
};

export default AutomaticGetStarted;
